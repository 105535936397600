import React from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../static/css/shares.css';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import { RedditShareButton, RedditIcon } from 'react-share';


import ReactGA from 'react-ga4';
ReactGA.initialize('G-F905EDBSX3');

export default function Shares({game, level, words}) {

	const { t } = useTranslation();

	var text = t("whatsappText");
	var title = {
		"chain": "Word Chain"
	};
	text = text.replace("GAME", title[game]).replace("LEVELNUMBER", level);

	var detectedLanguage = window.location.pathname.split('/')[1];
    if(detectedLanguage == "" || detectedLanguage.length > 2) {
    	detectedLanguage = "en";
    }
    var link = "https://www.word-chain.co/";
    const [linkCopied, setLinkCopied] = useState(false);

	function sendEvent(platform) {
		ReactGA.event({
            category: 'Game',
            action: 'Share' + platform
        });
	}

	function copyLink() {
		ReactGA.event({
            category: 'Game',
            action: 'CopyLink'
        });
        setLinkCopied(true);

		var wordsColors = "My words: ";
        for(var i = 0; i < words.length; i++) {
            if(words[i] == "Error") { wordsColors += "❌"; }
            else { wordsColors += "🟩"; }
        }

        var linkWithText = "Play Word Chain! ⭐⭐⭐ \nI've just finished level " + level + "!\n\n" + wordsColors + "\n\n" + link;
		navigator.clipboard.writeText(linkWithText);
	}

	return (
		<div>
			<br/><br/>
			<h3>{t("shareHeader")}</h3>
			<p>{t("shareButtons")}</p>

			{linkCopied
                ? <>
                    <Button className="default-btn" variant="primary" onClick={copyLink}>{t("copyLink")}</Button><br/>
                    <p className="linkCopied">Copied results to the clipboard</p></>
                : <><Button className="default-btn" variant="primary" onClick={copyLink}>{t("copyLink")}</Button><br/></>

            }



			<div>
		      <FacebookShareButton url={link} title={text} className="btn-share">
		        <FacebookIcon size={50} round={false} borderRadius={14}  onClick={() => sendEvent("Fb")} />
		      </FacebookShareButton>

		      <TwitterShareButton url={link} title={text} className="btn-share" >
		        <TwitterIcon size={50} round={false} borderRadius={14} onClick={() => sendEvent("Twitter")} />
		      </TwitterShareButton>

		      <WhatsappShareButton url={link} title={text} className="btn-share">
		        <WhatsappIcon size={50} round={false} borderRadius={14} onClick={() => sendEvent("Whatsapp")} />
		      </WhatsappShareButton>

		      <RedditShareButton url={link} title={text} className="btn-share">
		        <RedditIcon size={50} round={false} borderRadius={14}  onClick={() => sendEvent("Reddit")} />
		      </RedditShareButton>
		    </div>
            <br/>


	    </div>
    );
}