import './style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Levels from './components/Levels';
import Minigames from './components/games/Minigames';

import tile1 from './static/tile1.png'
import tile2 from './static/tile2.png'
import tile3 from './static/tile3.png'
import logo from './icon.png'

import general from './static/json/general.json';

import { useNavigate } from "react-router-dom";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-F905EDBSX3');

function todayDate() {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const dateToday = yyyy + "-" + mm + "-" + dd;
	return dateToday;
}

function getNewestTileset() {
	var levels = general["chain"].filter(tset => tset["date"] <= todayDate()), ts;
	var lastPassed = localStorage.getItem( "chain-lastPassed");
	var allLevels = levels.length;

	if(lastPassed == undefined || lastPassed == null) {
		return 1;
	} else {
		if(Number(lastPassed)+1 > allLevels) { return "levels"; }
		return Number(lastPassed)+1;
	}

}

function getUrl(lang) {
	var tileset = getNewestTileset();
	return ("/" + lang + "/" + tileset);
}


function MainPage({lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function play() {
		ReactGA.event({
	        category: 'Game',
	        action: 'StartGameMainPage'
	    });
	}



    return (
        <div className="mainPart">
        <Container>
          <Row>
            <><h1>{t('title')}</h1>
    	    <p className="intro">{t('shortInstruction')}</p>
    	    <div><Button variant="success" className="default-btn" href={getUrl(lang)}>{t('play')}</Button>
    	    </div></>


    	    <br/>
    	    <a href={"/" + lang + "/levels"}>Show all levels</a>
          </Row>

        </Container>
        </div>
      );
}

function MoreInfo() {
	const { t } = useTranslation();
	return (
		<>
			<Container className="moreInfo" >
				<Row>
					<Col sm={12}>
						<h3>{t("gameHeader1")}</h3>
						<p>{t("gameText1")}</p>
					</Col>

					<Col sm={12}>
						<h3>{t("gameHeader2")}</h3>
						<p>{t("gameText2")}</p>
					</Col>
					<Col sm={12}>
						<h3>{t("gameHeader3")}</h3>
						<p>{t("gameText3")}</p>
					</Col>

				</Row>
			</Container>

			<div className="mainPart">
				<Container className="instructionInfo">
					<Row>
						<Col sm={12}>
							<h3>{t("howToPlay")}</h3>
							<ol>
								<li>{t("instruction1")}</li>
								<img alt="Word chain game - level 1" src="/images/instruction/instruction1.png"/>
								<li>{t("instruction2")}</li>
								<img alt="Word chain example" src="/images/instruction/instruction2.png"/>
								<li>{t("instruction3")}</li>
								<li>{t("instruction4")}</li>
								<li>{t("instruction5")}</li>
								<img alt="Word chain example 2" src="/images/instruction/instruction3.png"/>
								<li>{t("instruction6")}</li>
							</ol>
						</Col>
						<div><Button variant="success" className="default-btn" href={getUrl("en")}>{t('play')}</Button>
			            </div><br/>


					</Row>
				</Container>
			</div>

			<Container className="moreInfo" >
				<Row>
					<Col sm={12}>
						<h3>{t("gameHeader4")}</h3>
						<p>{t("gameText4")}</p>
					</Col>

				</Row>
				<Row>
					<Col sm={12}>
						<h3>{t("gameHeader5")}</h3>
						<p>{t("gameText5")}</p>
					</Col>

				</Row>
				<Row>
					<Col sm={12}>
						<h3>{t("gameHeader6")}</h3>
						<p>{t("gameText6")}</p>
					</Col>

				</Row>
			</Container>


		</>
	);
}

function MoreGames() {


	return (

		<div className="mainPart">
			<Minigames/>
		</div>
	);
}


function Main({lang}) {

	const { t } = useTranslation();
	const langBasic = (lang == "en") ? "" : lang;
	const description = t("description");
	const title = t("titleShort");

	return (
		<>
			<Helmet>
                <title>{title}</title>
                <link rel="canonical" href={"https://www.word-chain.co/" + langBasic} />
                <meta property="og:url" content={"https://www.word-chain.co/" + langBasic}  data-react-helmet="true" />

                <meta name="description" content={description}/>

                <meta property="og:image" content="https://www.word-chain.co/banner.png" />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>

            </Helmet>
			<MainPage lang={lang}/>
			<MoreInfo/>
			<Minigames/>
		</>

	);
};

export default Main;
