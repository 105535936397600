import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Instruction from './../Instruction';
import Shares from './../Shares';
import general from './../../static/json/general.json';

import {NotificationContainer, NotificationManager} from 'react-notifications';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-F905EDBSX3');


function todayDate() {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const dateToday = yyyy + "-" + mm + "-" + dd;
	return dateToday;
}


export default function Chain({view, onGameFinish, chooseTileset, playNextLevel, level, daily}) {

	const gameName = daily ? "daily" : "chain";
	const [nextGuess, setNextGuess] = useState("");
	const [clickedLetter, setClickedLetter] = useState(0);
	const [hint, setHint] = useState("");
	const { t } = useTranslation();

	const chainLevel = "➤" + general[gameName][Number(level)-1]["chain"];
	const answers = general[gameName][Number(level)-1]["answers"];
	const name = general[gameName][Number(level)-1]["name"];
	const date = general[gameName][Number(level)-1]["date"];
	const instruction = t("shortInstruction");

	const [chain, setChain] = useState(chainLevel);
	const [color, setColor] = useState('Z' + 'X'.repeat(chainLevel.length-1));

	const [words, setWords] = useState([]);
	const [error, setError] = useState("");


	const positions = [
		[54, 55, 56, 57, 58, 59, 60, 61, 62, 63],
        [53, 28, 29, 30, 31, 32, 33, 34, 35, 64],
        [52, 27, 10, 11, 12, 13, 14, 15, 36, 65],
        [51, 26, 9, 0, 1, 2, 3, 16, 37, 66],
        [50, 25, 8, 7, 6, 5, 4, 17, 38, 67],
        [49, 24, 23, 22, 21, 20, 19, 18, 39, 68],
        [48, 47, 46, 45, 44, 43, 42, 41, 40, 69],
        [79, 78, 77, 76, 75, 74, 73, 72, 71, 70]
	];

	const dir = [
		"Right", "Right", "Right", "Down", "Left", "Left", "Left", "Left", "Up", "Up",
		"Right", "Right", "Right", "Right", "Right", "Down", "Down", "Down", "Left", "Left",
		"Left", "Left", "Left", "Left", "Up", "Up", "Up", "Up", "Right", "Right",
		"Right", "Right", "Right", "Right", "Right", "Down", "Down", "Down", "Down", "Down",
		"Left", "Left", "Left", "Left", "Left", "Left", "Left", "Left", "Up", "Up",
		"Up", "Up", "Up", "Up", "Right", "Right", "Right", "Right", "Right", "Right",
		"Right", "Right", "Right", "Down", "Down", "Down", "Down", "Down", "Down", "Down",
		"Left", "Left", "Left", "Left", "Left", "Left", "Left", "Left", "Left", "No"
	];


	if(view == "play") {
		if(daily) {
			const bestScore = localStorage.getItem(gameName + "-" + level + "-best");
			const unlocked = localStorage.getItem(gameName + "-" + level + "-unlock");
			if(date == todayDate() || bestScore != null || unlocked) { }
			else {
				window.location.href = "/en/levels";
			}

		} else {
			var lastPassed = localStorage.getItem("chain-lastPassed");
			var nextLevel = 1;
			if(lastPassed == undefined || lastPassed == null) {
				nextLevel = 1;
			} else {
				nextLevel = Number(lastPassed) + 1;
				var allLevels = general["chain"].filter(tset => tset["date"] <= todayDate()).length;
				if(level > allLevels) {
					var url = window.location.href;
					var newUrl = url.replace(level, "levels");
	                window.location.href = newUrl;
				}
			}
			var url = window.location.href;
			if(nextLevel < level) {
				var newUrl = url.replace(level, nextLevel);
		        window.location.href = newUrl;
			}
		}
	}


	function showHint(){
		var word = "";
		for(var i = 0; i < answers.length; i++) {
			var w = answers[i];
			var pos = chain.indexOf(w);

			if(pos != -1) {
				word = w;
				break;
			}
		}
		ReactGA.event({
			category: 'Game',
		    action: 'ShowHint',
		    label: word
		});
		setHint("Try to find the word " + word + "." );
	}


	function guess(newGuess = "") {
		if(nextGuess != "") {
			newGuess = nextGuess;
		}
		if(answers.includes(newGuess)) {

			var pos = chain.indexOf(newGuess);
			if(pos == -1) {
				//already found or not accessible now

				if(words.includes(newGuess)) {
					setError("You've already found word " + newGuess);
				} else {
					setError("The word " + newGuess + " is not available now." );
				}
				setWords([...words, "Error"]);
				ReactGA.event({
		            category: 'Game',
		            action: 'BadGuess',
		            label: newGuess
		        });
				setNextGuess("");
				return;
			}

			setHint("" );
			var ll = newGuess.length;
			var newColor = color.substr(0, pos) + '0'.repeat(ll) + color.substr(pos+ll);
			setColor(newColor);
			setError("");

			setTimeout(function (){
	            var newChain = chain.substr(0, pos) + chain.substr(pos+ll);
                setChain(newChain);
                newColor = 'Z' + 'X'.repeat(chainLevel.length-1);
                setColor(newColor);

                setWords([...words, newGuess]);
				setNextGuess("");
				if(newChain.length == 1) {
					onGameFinish(100, 100);
				}
            }, 400);


		} else {
			setWords([...words, "Error"]);
			setError("Incorrect word.");
			setNextGuess("");
			setColor('Z' + 'X'.repeat(chainLevel.length-1));

			ReactGA.event({
	            category: 'Game',
	            action: 'BadGuess',
	            label: newGuess
	        });
		}


	}

	function nothing() {}

	function checkGuess(guess) {
		setNextGuess(guess.toUpperCase());
	}

	function getWord(word) {

		if(word != "Error") {
			return (<span className="foundWord">{word}</span>);
		} else {
			return (<span className="foundWord badWord">{word}</span>);
		}

	}

	function lines(pos) {

		if(pos == chain.length - 1) {return <></>;}
		var line = dir[pos];
        return (<div className={"lineToNext lineToNext" + line} ></div>);

    }

    function clickLetter(pos, letter) {

		if(clickedLetter != 0) {
			if(clickedLetter == pos) {
				setClickedLetter(0);
				var newColor = 'Z' + 'X'.repeat(chainLevel.length-1);
                setColor(newColor);

			} else if(clickedLetter > pos) {
				setClickedLetter(pos);
				var newColor = 'Z' + 'X'.repeat(chainLevel.length-1);
				newColor = newColor.substr(0, pos) + '0' + newColor.substr(pos+1);
                setColor(newColor);

			} else {
				var word = chain.substring(clickedLetter, pos+1);

				setNextGuess(word);
				setClickedLetter("");
				guess(word);

			}
			return;
        }

		if(clickedLetter == 0) {
	        setClickedLetter(pos);
	        var newColor = color.substr(0, pos) + '0' + color.substr(pos+1);
            setColor(newColor);

	        ReactGA.event({
		        category: 'Game',
		        action: 'LetterClicked'
		    });
			return;
		}

    }


	const boardCode = (<div className="fullBoard">

			<>
				<h1> LEVEL { level } </h1>
				{
					daily
					? <><h2> DAILY { date } </h2><h2> In daily games, the word category is unknown.</h2> </>
					: <h2> { name } </h2>
				}
				<p> { instruction }</p>

				{ view == "play"
                ?   <>Click on the <b>first and last letter</b> to highlight the word:

                <table className="chainTable">
				<body>
				{
					positions.map(row =>
						<tr className="chainTr">
						{row.map(pos =>
							(pos < chain.length ) ? <td className={
								(color[pos] == "0") ?  "chainTd removedLetter"
								: (color[pos] == "Z") ?  "chainTd emptyLetter"
								: (color[pos] == "C") ?  "chainTd clickedLetter"
                                  								: "chainTd "
								} >
								<div className="chainLetter" onClick={(e) => clickLetter(pos, chain[pos])}>


									{chain[pos]}
								</div>
								{lines(pos)}
							</td>
							: <td className="chainTdEmpty"></td> )
							}
						</tr>
					)
				}
				</body>
				</table></>
				: "" }

				<div className="foundWords">{words.map(word => getWord(word))}</div>


				{ view == "play"
				?    <Row className="guessRowButton">
					 { /*
						Or enter the found word here:

						<Col sm={8}><Form.Control as="input" className="guessInput" value={nextGuess}
						onKeyPress={(e) => {(e.key === 'Enter' ? guess() : nothing())}}
						onChange={(e) => checkGuess(e.target.value)}/></Col>

						<Col sm={4}><Button variant="success" className="small-btn" onClick={guess}>Guess</Button></Col>
						<br/>
						<br/>*/ }

					{   error != "" ? <div className="guessError">{ error }</div> : ""}

					<Button style={{"margin": "auto", "margin-top": "10px"}} variant="success" className="small-btn" onClick={showHint}>Hint</Button>
					<p>{hint}</p>
					</Row>
				: <div>

					<br/><h1>{t('win')}</h1>
		            <h4>
		                { "You found all words!"}
		            </h4>

		            {
		                daily
		                ? <><Button variant="success" className="default-btn" onClick={chooseTileset}>Choose level</Button></>
		                : <><Button className="default-btn" onClick={playNextLevel}>Play next level </Button><br/>
                           <Button variant="success" className="small-btn" onClick={chooseTileset}>Show all levels</Button>
		                </>
		            }


					<Shares game="chain" level={level} words={words}/>

					</div>
				}
			</>

	    </div>
	);

    return boardCode;
}

