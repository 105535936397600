import React from 'react';

import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import '../../static/css/tilesets.css';

import { useNavigate } from "react-router-dom";

import general from '../../static/json/general.json';
import Minigames from "./Minigames.js"

import ReactGA from 'react-ga4';
ReactGA.initialize('G-F905EDBSX3');

function MainPage({game, lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function play() {

		ReactGA.event({
	        category: 'Game',
	        action: 'StartMiniGame'
	    });

	}

	function getNewestTileset() {
		var tilesets = general["waiting"], ts;
		const todayDate = new Date();
		var dateDate = new Date();

		for(var i = 0; i < tilesets.length; i++) {
			ts = tilesets[i];
			dateDate = new Date(ts["date"]);
			if(dateDate <= todayDate) {
            	return ts["name"];
            }
		}
	}

	function link() {
		var tileset = getNewestTileset();
        if(game == "same") {tileset = "two";}
        if(game == "lines") {tileset = "stripes";}
		return ("/" + lang + "/" + game + "/" + tileset);
	}

	const title = t(game + "Title");
	const shortInstruction = t(game + "Instruction");

    return (
        <div className="mainPart">
        <Container>
          <Row>
            <><h1>{title}</h1>
    	    <p className="intro">{shortInstruction}</p>
    	    <div><Button variant="success" className="default-btn" href={link()}>{t('play')}</Button>
    	    </div>
    	    <a href={"/" + lang + "/" + game + "/tilesets"}>{t('chooseTileset')}</a>
    	    </>

          </Row>

        </Container>
        </div>
      );
}

function MoreInfo({game}) {
	const { t } = useTranslation();
	return (
		<>
			<Container className="moreInfo" >
				<Row>
					<Col sm={9}>
						<h3>{t(game + "Header1")}</h3>
						<p>{t(game + "Text1")}</p>
					</Col>
					<Col sm={3}><img alt="Tile1" src={"/images/games/" + game + "/pic1.png"} /></Col>
				</Row>
				<Row>
					<Col sm={3}><img alt="Tile2" src={"/images/games/" + game + "/pic2.png"}  /></Col>
					<Col sm={9}>
						<h3>{t(game + "Header2")}</h3>
						<p>{t(game + "Text2")}</p>
					</Col>
				</Row>
				<Row>
					<Col sm={9}>
						<h3>{t(game + "Header3")}</h3>
						<p>{t(game + "Text3")}</p>
					</Col>
					<Col sm={3}><img alt="Tile3" src={"/images/games/" + game + "/pic3.png"} /></Col>
				</Row>
				<Row>
					<h3>{t(game + "Header4")}</h3>
					<p>{t(game + "Text4")}</p>

				</Row>
			</Container>

			<Container className="moreIconTiles">
	            <Row>
	                <img alt="Tile1" src={"/images/games/" + game + "/pic1.png"} />
	                <img alt="Tile2" src={"/images/games/" + game + "/pic2.png"} />
	                <img alt="Tile3" src={"/images/games/" + game + "/pic3.png"} />
	            </Row>
	        </Container>
		</>
	);
}

function Main({lang, game}) {

	const { t } = useTranslation();
	const langBasic = (lang == "en") ? "" : lang;

	React.useEffect(() => {
          window.scrollTo(0, 0);
        }, []);

    const title = "Word Chain - " + t(game + "Title");
    const description = t(game + "Description");

	return (
		<>
			<Helmet>
                <title>{ title }</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={"https://www.word-chain.co/" + lang + "/" + game} />
                <meta property="og:url" content={"https://www.word-chain.co/" + lang + "/" + game}  data-react-helmet="true" />
                <meta property="og:image" content={"https://www.word-chain.co/images/games/" + game + "/default.png"} />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
            </Helmet>
			<MainPage game={game} lang={lang}/>
			<br/><br/>

			<MoreInfo game={game}/>
			<div className="mainPart">
            	<Minigames/>
            </div>
		</>

	);
};

export default Main;
