import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';



function Test() {
	const { t } = useTranslation();

    return (
        <div className="mainPart">
        <Helmet>
        	            <title>Word Chain - How to play?</title>
        	            <meta name="description" content="Choose your favorite level and play Word Chain" />
        	            <link rel="canonical" href={"https://www.word-chain.co/howtoplay"} />
        	            <meta property="og:url" content={"https://www.word-chain.co/howtoplay"} />
        	            <meta property="og:image" content="https://www.word-chain.co/banner2.png" />
        	            <meta property="og:title" content="Word Chain - How to play?" />
        	        </Helmet>
        <Container>
            <Row>

                <h1>How to play Tiles?</h1>
    	        <p className="intro">Beginner's guide to Tiles game</p>

				<div className="paragraph">

					<h2>1. Basic rules</h2>
					<p>X</p>

					<h2>2. What is the goal of Tiles?</h2>
					<p>X</p>

					<h2>3. Highest possible score in Tiles</h2>
					<p>X</p>

					<p>
					Mastering the NYT Tiles puzzle takes a combination of observation, strategy, and a touch of patience.
					As you explore the details of this interesting puzzle, have fun with the challenge and feel good when you clear the tiles.
					Happy matching!
					</p>


				</div>
    	        <br/>
            </Row>

        </Container>
        </div>
    );
}
export default Test;