import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
import Minigames from './games/Minigames';
import Comments from './Comments';
import '../static/css/tilesets.css';
import GoogleAd from './GoogleAd';

import general from '../static/json/general.json';

function bigName(gameName) {
	const names = {
        "chain": "WORD CHAIN",
        "daily": "DAILY MODE",
    };
    if(gameName in names) {
        return names[gameName];
    } else {
		return gameName.toUpperCase();
	}
}

function getUrl({game, tileset, language}) {
	if(game === "chain") {
		return ("/" + language + "/" + tileset);
	} else {
		return ("/" + language + "/" + game + "/" + tileset);
	}
}

function playGame({game, tileset}) {
	ReactGA.event({
		category: 'Game',
	    action: 'StartGame'
	});
}

function playTileset({nextLevel, game, tileset,language}) {
	if(nextLevel >= tileset) {
		ReactGA.event({
		    category: 'Game',
		    action: 'StartGame'
		});
		window.location.href = getUrl({game, tileset, language});
	}
}

function stars() {
	return (<><img className="levelStar" src={"/images/star.png"}/>
        <img className="levelStar" src={"/images/star.png"}/>
        <img className="levelStar" src={"/images/star.png"}/></>);
}

function NewLevel({game, tileset, name, date}) {

    return (
        (<Col className='tilesetContainer'>
            <Card className='tilesetCard tilesetHidden' >

                <Card.Body>
                    <Card.Title>LEVEL {tileset}</Card.Title>
					<Card.Text> </Card.Text>
                    <Card.Text>  { name }  </Card.Text>

                </Card.Body>
            </Card>
        </Col>)
    );
}
function Tileset({game, tileset, name, date}) {
	const { t } = useTranslation();
	const language = window.location.pathname.split('/')[1];
	const bestScore = localStorage.getItem(game + "-" + tileset + "-best");

	var played = (bestScore != null);
	var lastPassed = localStorage.getItem( "chain-lastPassed");
	var nextLevel = 1;
	if(lastPassed == undefined || lastPassed == null) {
		nextLevel = 1;
	} else {
		nextLevel = Number(lastPassed) + 1;
	}
	var tilesetAvailable = (nextLevel < Number(tileset)) ? "tilesetHidden" : "";

    return (
        (<Col className='tilesetContainer'>
            <Card onClick={() => playTileset({nextLevel, game, tileset, language})} className={'tilesetCard ' + tilesetAvailable} >

                <Card.Body>
                    <Card.Title>LEVEL {tileset}</Card.Title>
                    <Card.Text>{ date }</Card.Text>
                    {
                        played
                        ? <><Card.Text>
	                            { name } {stars() }
                            </Card.Text>
                            <Button href={getUrl({game, tileset,language})} className="small-btn light-btn" variant="primary"
                                onClick={() => playGame({game, tileset})}>Play again</Button>
                            </>
                        : <Card.Text>  { name }  </Card.Text>
					}
					{
                        (nextLevel == Number(tileset) && date != "")
                        ?   <Button href={getUrl({game, tileset, language})} className="small-btn" variant="primary"
                                onClick={() => playGame({game, tileset})}>{t("play")}</Button>
                            :  ""

					}
                </Card.Body>
            </Card>
        </Col>)
    );
}

function Daily({game, tileset, name, date, setKeys}) {
	const { t } = useTranslation();
	const language = window.location.pathname.split('/')[1];
	const bestScore = localStorage.getItem(game + "-" + tileset + "-best");

	var played = (bestScore != null);
	var lastPassed = localStorage.getItem( "daily-lastPassed");
	var nextLevel = 0;
	var unlockedVar = localStorage.getItem(game + "-" + tileset + "-unlock");
	var tilesetAvailableVar = (played || date == todayDate() || unlockedVar) ? "" : "tilesetHidden";

	const [unlocked, setUnlocked] = useState(unlockedVar);
	const [tilesetAvailable, setTilesetAvailable] = useState(tilesetAvailableVar);

	function unlock({game, tileset}) {
		console.log(game, tileset);
		var haveKeys = localStorage.getItem("chain-daily-keys");
		if(haveKeys != null && haveKeys >=2) {
			localStorage.setItem(game + "-" + tileset + "-unlock", true);
			localStorage.setItem("chain-daily-keys", haveKeys-2);
			setUnlocked(true);
			setTilesetAvailable(true);
			setKeys(haveKeys-2);

			ReactGA.event({
            	category: 'Game',
            	action: 'UnlockLevel',
            	value: tileset
            });
		}
	}

    return (
        (<Col className='tilesetContainer'>
            <Card onClick={() => playTileset({nextLevel, game, tileset,language})} className={'tilesetCard ' + tilesetAvailable} >

                <Card.Body>
                    <Card.Title>LEVEL {tileset}</Card.Title>
                    <Card.Text>{ date }</Card.Text>
                    {
                        played
                        ? <><Card.Text>
	                            { name } {stars() }
                            </Card.Text>
                            <Button href={getUrl({game, tileset,language})} className="small-btn light-btn" variant="primary"
                                onClick={() => playGame({game, tileset})}>Play again</Button>
                            </>
                        : (todayDate() == date && !played) || unlocked
                            ?   <><Card.Text></Card.Text>
                                    <Button href={getUrl({game, tileset, language})} className="small-btn" variant="primary"
                                        onClick={() => playGame({game, tileset})}>{t("play")}</Button></>
                            :  <><Card.Text>    </Card.Text>
                                    <Button className="small-btn" variant="primary" onClick={() => unlock({game, tileset})}>Unlock (2 <img className="levelStar" src={"/images/key.png"}/>)</Button>
                                </>
					}
                </Card.Body>
            </Card>
        </Col>)
    );
}


function todayDate() {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const dateToday = yyyy + "-" + mm + "-" + dd;
	return dateToday;
}

function getLevels(game) {
	return general[game].filter(tset => tset["date"] <= todayDate()).map(tset => ({"id": tset["id"], "name": tset["name"], "date": tset["date"]}));
}
function getDaily(game) {
	return general["daily"].filter(tset => tset["date"] <= todayDate()).map(tset => ({"id": tset["id"], "name": tset["name"], "date": tset["date"]}));
}


function GamePicker({mode, myStars, totalStars, setMode}) {

	const detectedLanguage = window.location.pathname.split('/')[1];
	function goToGame(game) {
		if(game == "chain") {
			setMode("chain");
		} else {
			setMode("daily");
		}
	}

	function getCol(gameName) {
		return (
			<Col className={ (mode == gameName) ? " gamePicked gamePicker" : "gamePicker"} onClick={() => goToGame(gameName)}>{bigName(gameName)}
            <br/> <img className="gameSmallStar" src={"/images/star.png"}/> {myStars[gameName]} / {totalStars[gameName]}</Col>);
	}

	var games = ["chain", "daily"];
	return (<Row>
        { getCol(games[0]) }
        { getCol(games[1]) }
    </Row>);
}



const Levels = ({game}) => {
	const { t } = useTranslation();
	var levels = getLevels(game);

	var daily = getDaily(game);
	var haveKeys = localStorage.getItem("chain-daily-keys");
	if(haveKeys == null) {haveKeys = 0;}
	const [keys, setKeys] = useState(haveKeys);
	const [mode, setMode] = useState("chain");

	let language = window.location.pathname.split('/')[1];
	if(language === "") {
		language = "en";
	}

	function gameToUpper({game}) {
		return game.toUpperCase()

	}

	function getPoints({tileset, game}) {
		tileset = tileset["id"];
		var best = Number(localStorage.getItem(game + "-" + tileset + "-best"));
		console.log(best);
		if(best == 100) {
			return 3;
		} else {
			return 0;
		}

	}

	function countPoints(game) {
		var stars = 0;
		var tsets = getLevels(game);

		for(var i = 0; i < tsets.length; i++) {
			var tileset = tsets[i];
			stars += getPoints({tileset, game});
		}
		return stars;
	}

	const myStars = {"chain": countPoints("chain"), "daily": countPoints("daily")};
	const totalStars = {"chain": 3*levels.length, "daily": 3*daily.length};

	const tilesetHtml = (
        <div>

	        <Helmet>
	            <title>Word Chain - Available Levels</title>
	            <meta name="description" content={"Choose your favorite level and play " + game} />
	            <link rel="canonical" href={"https://www.word-chain.co/" + language + "/levels"} />
	            <meta property="og:url" content={"https://www.word-chain.co/" + language + "/levels"} />
	            <meta property="og:image" content="https://www.word-chain.co/banner2.png" />
	            <meta property="og:title" content={"Word Chain - " + gameToUpper({game}) + "- Available Tilesets"} />
	        </Helmet>


	        <div className="mainPart topPart">
		        <Container>
		            <GoogleAd type="tilesetsTop"/>
		            <br/>

		            <h1>{bigName(game)}</h1>
		            {/* <h2>New levels every day! </h2> */ }

		            <br/>
			                <GamePicker mode={mode} myStars={myStars} totalStars={totalStars} setMode={setMode} />


				</Container>

	        </div>

	        <div>
            	<Container>

					<br/><br/>
		            {
		                (mode =="chain")
		                ?   <Row>
		                        {levels.map(tileset => ( <Tileset game={game}  tileset={tileset["id"]} name={tileset["name"]}  date={tileset["date"]} daily={false} />))}
		                        {/* <NewLevel game={game}  tileset={levels.length + 1} name="New level tomorrow" date=""  />  */ }
		                    </Row>

		                :   <>
		                        {/*<h3>Solve today's game</h3>
		                        {
					                <Row>
					                     <Daily game={"daily"}  tileset={daily[daily.length-1]["id"]} name={daily[daily.length-1]["name"]}
					                            date={daily[daily.length-1]["date"]} daily={true} setKeys={setKeys} />
					                     <NewLevel game={"daily"}  tileset={daily.length + 1}  name="New level tomorrow" date="" />

					                </Row>
					            }
					            <h3>All daily levels</h3>*/}
					            <center> <h3><b>Keys: {keys} <img className="levelStar" src={"/images/key.png"}/> </b> </h3>
					            <p>You need 2 keys to unlock the archived daily levels. You get 1 key for each new level you complete.</p></center>
					            {
					                <Row>

					                    {daily.map(tileset => ( <Daily game={"daily"}  tileset={tileset["id"]} name={tileset["name"]}  date={tileset["date"]} daily={true} setKeys={setKeys} />))}

					                </Row>
					            }

		                    </>
		            }
			
		            <Minigames/>


		            <GoogleAd type="tilesetsBottom"/>
					</Container>
             </div>


			<Comments/>

        </div>
      );

    return tilesetHtml
}
export default Levels;

