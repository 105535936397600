import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import '../../static/css/tilesets.css';



function Minigame({game, title, type}) {


	const { t } = useTranslation();

	var detectedLanguage = window.location.pathname.split('/')[1];
    if(detectedLanguage === "" || detectedLanguage.length > 2) {
    	detectedLanguage = "en";
    }


    function textToUpper({game}) {
        if(game === "same") {
            return "SAME GAME";
        }
        return game.toUpperCase();
	}

	function gameHref(type, detectedLanguage, game) {
		if(type === "out") {
			var href = {
				"spellbee": "https://www.spell-bee.com/index.html?utm_source=WordChain",
				"tiles": "https://www.unlimited-tiles.com/?utm_source=WordChain",
				"wordsearch": "https://www.unlimited-tiles.com/wordsearch?utm_source=WordChain",
				"wordchain": "https://www.word-chain.co/?utm_source=WordChain",
				"strands": "https://www.strands-wordle.com/?utm_source=WordChain",
				"wordle": "https://www.infinite-wordle.com/?utm_source=WordChain",
				"angle": "https://www.angle-game.com/index.html?utm_source=WordChain"
			}
			return href[game];
		}

		if(game === "tiles") {
			return detectedLanguage + "/tilesets";
		}
		return "/" + detectedLanguage + "/" + game + "/tilesets";
	}

	function getAlt({game}) {
		var alts = {
			"tiles": "tiles nyt game",
			"same": "same game",
			"lines": "color lines game",
			"pairs": "match pairs game",
			"infinite": "infinite tiles game",
			"spellbee": "spell bee game",
			"strands": "strands wordle game",
			"wordle": "infinite wordle game",
			"wordchain": "word chain game",
			"wordsearch": "word search book",
			"angle": "angle game"
		};
		return alts[game];
	}

    return (
        <Col className='tilesetContainer'>
            <Card className='tilesetCard' style={{"height": "260px"}}>
                <a href={gameHref(type, detectedLanguage, game)}>
                    {
                        type === "in" ? <Card.Img alt={getAlt({game})} variant="top" src={"/images/games/" + game + "/default.png"}  />
                        : <Card.Img alt={getAlt({game})} variant="top" src={"/images/moregames/" + game + ".png"}  />
                    }

                    <Card.Body>
                        <Card.Title>{title}</Card.Title>
                        <Card.Text>{""}</Card.Text>
                        <Button className="small-btn" variant="primary">{t("play")}</Button>

                    </Card.Body>
                </a>
            </Card>
        </Col>
    );
}




const Moregames = () => {
	const { t } = useTranslation();
	const moregames = [
		{"name": "tiles", "title": "TILES" },
		{"name": "spellbee", "title": "SPELL BEE"},
		{"name": "angle", "title": "GUESS THE ANGLE"},
		{"name": "wordsearch", "title": "TWISTED WORD SEARCH"},
	];



	const gamesHtml =  (

		<Container>
	        <h2>More cool games</h2>
	        {
	            <Row>
	                {moregames.map(game => ( <Minigame game={game["name"]} title={game["title"]} key={game} type="out"/>))}

	            </Row>
	        }
	    </Container>
    );

    return gamesHtml;
}
export default Moregames;
